var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",style:(_vm.$store.state.displayArea.rightWidth + _vm.$store.state.displayArea.height)},[_c('div',{staticClass:"baidu-api",attrs:{"id":"allmap1"}}),(_vm.isCustom)?_c('div',{staticClass:"search flex_align"},[_c('div',{staticClass:"search_input flex_align"},[_c('el-autocomplete',{staticStyle:{"width":"100%"},attrs:{"id":"suggestId","fetch-suggestions":_vm.querySearchAsync,"trigger-on-focus":false,"placeholder":"搜地点"},on:{"focus":_vm.focus,"blur":_vm.blur,"select":_vm.handleSelect},scopedSlots:_vm._u([{key:"default",fn:function({ item }){return [_c('div',{staticClass:"flex_align search_title",staticStyle:{"width":"100%"}},[_c('i',{staticClass:"el-icon-search fl mgr10",staticStyle:{"margin-right":"10px"}}),_c('div',{staticClass:"flex_align"},[_vm._v(" "+_vm._s(item.title)+" ("+_vm._s(item.address)+") ")])])]}}],null,false,3044195014),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"search_x mouse",on:{"click":_vm.eliminate}},[_vm._v("×")])],1),_c('div',{staticClass:"search_but mouse",on:{"click":function($event){return _vm.query()}}},[_c('img',{attrs:{"src":require("@/assets/group/search.png"),"alt":""}})])]):_vm._e(),_c('mapcustomfence',{ref:"mapcustomfence",attrs:{"mapIconDisplay":_vm.mapIconDisplay,"baiduMap":_vm.map},on:{"openDrawing":_vm.openDrawing,"drawPolylines":_vm.drawPolylines,"drawCircle":_vm.drawCircle,"drawRectangle":_vm.drawRectangle,"previousStep":_vm.previousStep,"deleteFenceArea":_vm.deleteFenceArea,"switchOnlineState":_vm.switchOnlineState,"changeMap":_vm.changeMap}}),_c('msgTip',{attrs:{"showcontent":_vm.msgTipData.showcontent,"tipstext":_vm.msgTipData.tipstext}}),(_vm.isBool)?_c('enclosure',{ref:"enclosure",attrs:{"dialogTitle":_vm.dialogTitle,"efId":_vm.efId,"overlays":_vm.overlays},on:{"clearOverwrites":_vm.clearOverwrites}}):_vm._e(),(_vm.editRegion != 1)?_c('div',{style:({
  position: 'absolute',
  'z-index': '9999999',
  top: _vm.offsetTop + 'px',
  left: _vm.offsetLeft + 'px',
  display: _vm.isShowMouse,
}),attrs:{"id":"mouseOffset"}},[_vm._v(" 双击"+_vm._s(_vm.editRegion == 3 ? "创建" : "保存")+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }